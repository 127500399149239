<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div style="line-height: 10px">
        <h4>Available</h4>
        <span class="text-muted">Empower Company</span>
      </div>
      <b-button variant="primary" class="rounded-pill" size="sm" v-b-modal.add-department>
        Build New Department
      </b-button>
    </div>
    <b-row>
      <b-col md="3" v-for="department in departments" :key="department.id" v-b-modal="'detail_' + department.id">
        <b-card class="mt-2">
          <b-card-body align="center">
            <div class="text-primary">
              <feather-icon icon="BoxIcon" size="30"></feather-icon>
            </div>
            <b-card-text class="mt-2">
              <h4>{{ department.name }}</h4>
              <span>{{ department.users_count }} People Assigned</span>
            </b-card-text>
            <!-- <b-avatar-group size="32px">
              <b-avatar
                class="pull-up"
                :src="require('@/assets/images/portrait/small/avatar-s-5.jpg')"
              />
            </b-avatar-group> -->
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-for="department in departments"
      :key="department.id"
      :id="'detail_' + department.id"
      :title="department.name"
      size="md"
      hide-footer
    >
      <form @submit.prevent="form_update(department)">
        <b-form-group>
          <b-form-input v-model="department.name" type="text" placeholder="Department Name" />
        </b-form-group>

        <div class="text-right">
          <b-button
            type="button"
            variant="outline-danger"
            @click="deleteDepartment(department.id)"
            class="mr-1"
            size="sm"
            >Delete</b-button
          >
          <b-button type="submit" variant="primary" size="sm">Save</b-button>
        </div>
      </form>
    </b-modal>

    <b-modal id="add-department" title="Add Department" size="md" hide-footer>
      <form @submit.prevent="form_create">
        <b-form-group>
          <b-form-input v-model="name" type="text" placeholder="Department Name" />
        </b-form-group>

        <div class="text-right">
          <b-button type="submit" variant="primary">Save</b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      departments: [],
      name: "",
    };
  },
  mounted() {
    this.callApi({
      method: "GET",
      url: "/departments/fetch",
      params: {
        with_responsibilities: 1,
        assigned: 1,
      },
      success: (response) => {
        this.departments = response.result;
      },
      error: (error) => {
        console.log(error);
      },
    });
  },

  methods: {
    form_create() {
      this.callApi({
        method: "POST",
        url: "/departments/create",
        data: {
          level: 1,
          name: this.name,
        },
        success: (response) => {
          this.departments.push(response.result);
          this.$bvModal.hide("add-department");

          this.$toast({
            component: "@core/components/toastification/ToastificationContent.vue",
            props: {
              title: "Success",
              message: "Department has been created",
              type: "success",
            },
          });
        },
        error: (error) => {
          console.log(error);
        },
      });
    },
    form_update(department) {
      this.callApi({
        method: "POST",
        url: `/departments/update/${this.hashid(department.id)}`,
        data: {
          name: department.name,
        },
        success: (response) => {
          this.$bvModal.hide("detail_" + department.id);
        },
        error: (error) => {
          console.log(error);
        },
      });
    },
    deleteDepartment(id) {
      this.callApi({
        method: "DELETE",
        url: "/departments/delete/" + this.hashid(id),
        success: (response) => {
          this.departments = this.departments.filter((department) => department.id != id);
        },
        error: (error) => {
          console.log(error);
        },
      });
    },
  },
};
</script>
